import * as React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout";
import BigLogo from "../components/bigLogo";
import ContactForm from "../components/contactForm";

const KontaktPage = () => {
  return (
    <Layout>
      <BigLogo />
      <Container className="p-3">
        <h4>Formularz kontaktowy</h4>
          <Container className="px-2">
            <ContactForm />
          </Container>
      </Container>
      <Container className="p-3 lh-lg">
        <h4>Dane firmy</h4>
        <p className="px-2">Onvo sp. z o.o.</p>
        <p className="px-2">Kapitał Zakładowy: 100.000,00PLN</p>
        <p className="px-2">Al. Powstania Warszawskiego 15</p>
        <p className="px-2">31-539 Kraków</p>
        <p className="px-2">NIP: 6751741125</p>
        <p className="px-2">REGON: 387759618</p>
        <p className="px-2">KRS: 0000875138</p>
        <p className="px-2">email: <a href="mailto:biuro@onvo.pl">biuro@onvo.pl</a></p>
        <p className="px-2">nr telefonu: <a href="tel:+48789236034">789 236 034</a></p>
        <p className="px-2">facebook: <a href="https://www.facebook.com/OnvoBiuro/">fb.com/OnvoBiuro</a></p>
      </Container>
    </Layout>
  )
}

export default KontaktPage
